import { Box, Flex } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import MobileNav from "./mobileNavigation";
import NavBar from "./SideNavBar";
import "./Nav.css";

const Navigation = () => {
  useEffect(() => {}, [isMobile]);

  return (
    <Box>
      {isMobile ? (
        <MobileNav />
      ) : (
        <Flex
          className={"nav-div"}
          w={{ sm: "10rem", md: "15rem", lg: "15rem" }}
          bg={"blue.900"}
          display="flex" // Optional: Apply flex here if you want to control the nav items flexibly
          flexDirection="column" // Optional: Align nav items vertically
        >
          <NavBar />
        </Flex>
      )}
    </Box>
  );
};

export default Navigation;

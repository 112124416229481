import React from "react";
import { Box, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import "../About.css";
const AchievementsSection = ({ achievements }) => {
  return (
    <Box className={"tab-section"}>
      {achievements &&
        achievements.map((ach, index) => (
          <VStack
            className={"tab-section-stack"}
            key={ach.conference}
            align="stretch"
            spacing={4}
            mb={10}
            w={{ base: "100%", md: "50%" }}
          >
            <Heading size="lg" variant={"homePageSecondary"} color={"teal.500"}>
              {ach.conference}
            </Heading>
            <Box key={index} p={5} shadow="md" borderWidth="1px">
              <Text>
                {ach.city}, {ach.country}
              </Text>
              <Text>{ach.year}</Text>
              {/*TODO add a link after adding link in strapi*/}
              <Text fontWeight="bold">{ach.paper}</Text>
            </Box>

            <Divider />
          </VStack>
        ))}
    </Box>
  );
};

export default AchievementsSection;

import { ComponentStyleConfig } from "@chakra-ui/theme";
import { toREM } from "../../utils/helpers/toREM";

const Heading: ComponentStyleConfig = {
  sizes: {
    "5xl": {
      fontSize: { base: "4xl", xl: "5xl" },
      fontWeight: "light",
      lineHeight: "none",
    },
    "4xl": {
      fontSize: { base: "3xl", xl: "4xl" },
      fontWeight: "light",
      lineHeight: "none",
    },
    "3xl": {
      fontSize: { base: "2xl", xl: "3xl" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
    "2xl": {
      fontSize: { base: "xl", md: "16px", xl: "2xl" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
    xl: {
      fontSize: { base: "lg", md: "14px", xl: "xl" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
    lg: {
      fontSize: { base: "lg", md: "13px", xl: "lg" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
    md: {
      fontSize: { base: "md", md: "12px", xl: "md" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
    sm: {
      fontSize: { base: "sm", md: "10px", xl: "sm" },
      fontWeight: "light",
      lineHeight: 1.5,
    },
  },
  variants: {
    homePagePrimary: {
      fontFamily: "Caveat, sans-serif",
      fontSize: toREM(48),
      color: "white",
    },
    homePageSecondary: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "semibold",
      fontSize: toREM(22),
      color: "black",
    },
    secondary: {
      fontFamily: "Caveat ,cursive",
      fontWeight: 300,
      color: "black",
    },
  },
};

export default Heading;

import gql from "graphql-tag";

const GET_PROFILE = gql`
  query {
    profile {
      data {
        attributes {
          profileImgUrl
          education {
            start
            end
            school
            program
            degree
            city
            country
          }
          experience {
            start
            end
            position
            company
            city
            country
            tools
          }
          publications {
            year
            country
            conference
            city
            paper
          }
          info {
            full_name
            title
            email
            summary
            github {
              link
              name
            }
            linkedIn {
              link
              name
            }
            languages {
              name
              level
            }
          }
        }
      }
    }
  }
`;

export default GET_PROFILE;

import React, { useEffect, useState } from "react";
import { Box, Heading, Text, VStack, Divider } from "@chakra-ui/react";
import "../About.css";
const ExperienceSection = ({ experiences }) => {
  const [sortedExperiences, setSortedExperience] =
    useState<{ company: string; experiences: any }[]>();

  const groupAndSortExperiences = (experiences) => {
    // Group experiences by company
    const groupedByCompany = experiences.reduce((acc, experience) => {
      const { company } = experience;
      if (!acc[company]) {
        acc[company] = [];
      }
      acc[company].push(experience);
      return acc;
    }, {});

    // Sort experiences within each company by start date (descending)
    for (const company in groupedByCompany) {
      groupedByCompany[company].sort(
        (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
      );
    }

    // Sort companies based on the most recent start date of the positions within them
    const sortedCompanies = Object.entries(groupedByCompany)
      .sort((a, b) => {
        const lastStartDateA = a[1][0].start; // a[1] is the array of experiences for company a
        const lastStartDateB = b[1][0].start; // b[1] is the array of experiences for company b
        return (
          new Date(lastStartDateB).getTime() -
          new Date(lastStartDateA).getTime()
        );
      })
      .map(([company, experiences]) => ({
        company,
        experiences,
      }));

    return sortedCompanies;
  };

  useEffect(() => {
    setSortedExperience(groupAndSortExperiences(experiences));
  }, [experiences]);

  return (
    <Box className={"tab-section"}>
      {sortedExperiences &&
        sortedExperiences.map(({ company, experiences }) => (
          <VStack
            className={"tab-section-stack"}
            key={company}
            align="stretch"
            spacing={4}
            mb={10}
            w={{ base: "100%", md: "60%" }}
          >
            <Heading size="lg" variant={"homePageSecondary"} color={"teal.500"}>
              {company}
            </Heading>
            {experiences.map((exp, index) => (
              <Box key={index} p={5} shadow="md" borderWidth="1px">
                <Text fontWeight="semibold">{exp.position}</Text>
                <Text>
                  {exp.city}, {exp.country}
                </Text>
                <Text>
                  {exp.start} to {exp.end || "Present"}
                </Text>
                <Text>Tools: {exp.tools}</Text>
              </Box>
            ))}
            <Divider />
          </VStack>
        ))}
    </Box>
  );
};

export default ExperienceSection;

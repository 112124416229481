import React from "react";
import { Box, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import "../About.css";
const EducationSection = ({ education }) => {
  return (
    <Box className={"tab-section"}>
      {education &&
        education.map((edu, index) => (
          <VStack
            className={"tab-section-stack"}
            key={edu.school}
            align="stretch"
            spacing={4}
            mb={10}
            w={{ base: "100%", md: "50%" }}
          >
            <Heading size="lg" variant={"homePageSecondary"} color={"teal.500"}>
              {edu.school}
            </Heading>
            <Box key={index} p={5} shadow="md" borderWidth="1px">
              <Text fontWeight="bold">
                {edu.degree} {edu.program}
              </Text>
              <Text>
                {edu.city}, {edu.country}
              </Text>
              <Text>
                {edu.start} to {edu.end || "Present"}
              </Text>
              <Text>{edu.degree}</Text>
            </Box>

            <Divider />
          </VStack>
        ))}
    </Box>
  );
};

export default EducationSection;

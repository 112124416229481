import React from "react";
import { Box, Divider, Flex, Progress, Text, VStack } from "@chakra-ui/react";

const LanguagesSection = ({ languages }) => {
  const determineLevel = (level) => {
    switch (level) {
      case 5:
        return "Native";
      case 4:
        return "Fluent";
      case 3:
        return "Very Good";
      case 2:
        return "Good";
      case 1:
        return "Basic Level";
      default:
        return "";
    }
  };

  return (
    <Box className={"languages-section"}>
      {languages &&
        languages.map((lang, index) => (
          <VStack
            className={"languages-section-stack"}
            key={lang.name}
            align="stretch"
            spacing={4}
            mb={10}
            w={{ base: "100%", md: "50%" }}
          >
            <Flex key={index} p={5}>
              <Text mr={20} fontWeight="semibold" flex={1}>
                {lang.name}
              </Text>
              <Text flex={1}>{determineLevel(lang.level)}</Text>
            </Flex>
            <Box pl={5}>
              <Progress
                size={"sm"}
                colorScheme="blue"
                value={lang.level * 20}
              />
            </Box>

            <Divider />
          </VStack>
        ))}
    </Box>
  );
};

export default LanguagesSection;

import { ThemeOverride } from "@chakra-ui/react";

const fonts: ThemeOverride["fonts"] = {
  body: "Oxygen, system-ui, Stint Ultra Condensed, san-serif, Handlee, kalam, Monda",
  heading:
    'Droid Sans, Georgia, serif, Helvetica Neue, Merriweather, "Merriweather Sans", Calligraffitti, cursive, Caveat, "Permanent Marker", Marhey, kalam, Monda',
  mono: '"Roboto Mono", Menlo, monospace',
};

export default fonts;

import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import SideNavLink from "./SideNavLink";
import { navLinks } from "./SideNavBar";

const MobileNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <Flex position={"absolute"} zIndex={1}>
      <Button
        variant={"text"}
        ref={btnRef}
        onClick={onOpen}
        sx={{ m: 5, borderRadius: 0, backgroundColor: "rgba(0,0,0,0.4)" }}
      >
        <HamburgerIcon color={"white"} fontSize={"2xl"} />
      </Button>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={"left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={"blue.900"}>
          <DrawerCloseButton fontSize={"2xl"} color={'white'}/>
          <DrawerBody>
            <Box p={5}>
              <Flex
                sx={{
                  margin: "2rem auto",
                  width: "110px",
                  height: "110px",
                  background: "rgb(255 255 255 / 20%)",
                  borderRadius: "50%",
                }}
              >
                <Avatar
                  className={"avatar-nav"}
                  size="xl"
                  name="Sarah Ghobj"
                  src="https://res.cloudinary.com/dsyxohckg/image/upload/v1678055412/small_passport_Jan2023_1f1c48fe1a_fb4c3cd035.jpg"
                  sx={{
                    margin: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </Flex>
              <Box w={"100%"} m={"2rem auto"} pt={5} pb={5}>
                {navLinks.map((item, i) => (
                  <SideNavLink item={item} key={i} />
                ))}
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MobileNav;

import { Box, Heading, Link, Stack } from "@chakra-ui/react";
import "./Nav.css";

const SideNavLink = ({ item }) => {
  return (
    <Box className={"nav-link-container"}>
      <Link
        href={item.link}
        className={"nav-link"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Stack
          direction="row"
          p={1}
          className={"nav-item-stack"}
          gap={2}
          flex={1}
        >
          {item.icon}
          <Heading
            className={"nav-item-title"}
            fontFamily={"Caveat ,cursive"}
            color={"beaublue"}
            size={"md"}
            alignContent={"center"}
          >
            {item.title.toUpperCase()}
          </Heading>
        </Stack>
      </Link>
    </Box>
  );
};

export default SideNavLink;

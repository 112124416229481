import {
  Avatar,
  Box,
  Flex,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import { IoAirplaneSharp, IoHome, IoPersonSharp } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import SideNavLink from "./SideNavLink";
import "./Nav.css";
import MobileNav from "./mobileNavigation";

export const navLinks = [
  {
    title: "Home",
    link: "/",
    icon: <IoHome className={"nav-link-icon"} />,
  },
  {
    title: "About Me",
    link: "/about",
    icon: <IoPersonSharp className={"nav-link-icon"} />,
  },
  {
    title: "Blog",
    link: "/travel-blog",
    icon: <IoAirplaneSharp className={"nav-link-icon"} />,
  },
  {
    title: "Contact Me",
    link: "/contact",
    icon: <MdMarkEmailUnread className={"nav-link-icon"} />,
  },
];

const SideNavBar = () => {
  return (
    <Box p={5}>
      <Flex
        sx={{
          margin: "2rem auto",
          width: "110px",
          height: "110px",
          background: "rgb(255 255 255 / 20%)",
          borderRadius: "50%",
        }}
      >
        <Avatar
          className={"avatar-nav"}
          size="xl"
          name="Sarah Ghobj"
          src="https://res.cloudinary.com/dsyxohckg/image/upload/v1678055412/small_passport_Jan2023_1f1c48fe1a_fb4c3cd035.jpg"
          sx={{
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Flex>
      <Box w={"100%"} m={"2rem auto"} pt={5} pb={5}>
        {navLinks.map((item, i) => (
          <SideNavLink item={item} key={i} />
        ))}
      </Box>
    </Box>
  );
};
export default SideNavBar;

import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import GET_HOMEPAGE from "../../utils/queries/getHomepage";
import "./Portfolio.css";
import BoxStack from "../../components/boxStack/BoxStack";
import {isMobile, isTablet} from "react-device-detect";

const Home = () => {
  const [coverTitle, setCoverTitle] = useState("");
  const [coverSubTitle, setCoverSubTitle] = useState("");
  const [coverImg, setCoverImg] = useState("");

  const { data } = useQuery(GET_HOMEPAGE);

  const textAlign = useBreakpointValue({ base: "center", sm: "left" });

  useEffect(() => {
    if (data) {
      console.log(data);
      const { homepage } = data;

      const { title, description, cover } = homepage.data.attributes;

      const { title: coverTitle, subtitle: coverSubtitle, imgUrl } = cover;
      setCoverTitle(coverTitle);
      setCoverSubTitle(coverSubtitle);
      setCoverImg(imgUrl);
    }
  }, [data]);

  // Define styles for mobile, tablet, and desktop
  const textStyle = isMobile
      ? { fontSize: 'md', top: '12%', left: '5%' }
      : isTablet
          ? { fontSize: 'md', top: '25%', left: '15%' }
          : { fontSize: 'lg', top: '30%', left: '20%' };


  return (
    <Flex h={"100vh"} position={"relative"} maxW={'100vw'} overflow={'hidden'}>
      {coverImg && (
        <>
          <Image src={coverImg} objectFit={"cover"} h={"100%"} w={"100%"} />
          <Box
            className="text-area-home"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center" // Center text vertically in the box
            p={{ base: 0, md: 8 }}
            alignItems={ 'start' }
            {...textStyle}
          >
            <Heading
              m={"auto 0"}
              justifyContent={"left"}
              size={"4xl"}
              fontFamily={"Caveat ,cursive"}
            >
              {coverTitle}
            </Heading>
            <h3>{coverSubTitle}</h3>
          </Box>
        </>
      )}
    </Flex>
  );
};
export default Home;

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const httpLink = createHttpLink({
  uri: `https://portfolio-backend-sg-3a0334c1bd91.herokuapp.com/graphql`,
  // uri: `http://localhost:1337/graphql`,
});

export const client = new ApolloClient({
  ssrMode: typeof window === "undefined", // set to true for SSR
  link: httpLink,
  cache: new InMemoryCache(),
});

import React from "react";
import "@fontsource/stint-ultra-condensed/400.css";
import "@fontsource/alumni-sans/400.css";
import "@fontsource/bad-script";
import "@fontsource/permanent-marker";
import "@fontsource/marhey";
import "@fontsource/handlee";
import "@fontsource/caveat";
import "@fontsource/montserrat";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Home from "./pages/Portfolio/Home";
import MyProfile from "./pages/Portfolio/MyProfile";
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Sarah Ghobj</title>
        <link rel="canonical" href="https://sarah.ghobj" />
        <meta
          name="description"
          content="Sharing my travel experience and photos I take while exploring new places"
        />
        <meta
          name="keywords"
          content="Travel, Sarah Ghobj, Blog, Tourism, Visit Germany"
        />
      </Helmet>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/about"} element={<MyProfile />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}
export default App;

import { Box } from "@chakra-ui/react";
import "./Layout.css";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import Navigation from "../navigation/navigation";

const Layout = ({ children }) => {
  useEffect(() => {}, [isMobile]);
  return (
    <Box display={"flex"}>
      <Navigation />
      <Box className={"content-div"} flex={1} maxHeight="100vh">
        {children}
      </Box>
    </Box>
  );
};

export default Layout;

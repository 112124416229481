import gql from "graphql-tag";

const GET_HOMEPAGE = gql`
  query {
    homepage {
      data {
        attributes {
          title
          description
          cover {
            title
            subtitle
            imgUrl
          }
        }
      }
    }
  }
`;

export default GET_HOMEPAGE;

import {
  Box,
  Circle,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Link,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import Navigation from "../../components/navigation/navigation";
import { MdEmail } from "react-icons/md";
import { AiFillLinkedin, AiOutlineGithub } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import GET_PROFILE from "../../utils/queries/getProfile";
import Experience from "../../components/About/Experience/Experience";
import ExperienceSection from "../../components/About/Experience/ExperienceSection";
import EducationSection from "../../components/About/Education/EducationSection";
import AchievementsSection from "../../components/About/Achievements/AchievementsSection";
import languages from "../../components/About/Languages/Languages";
import LanguagesSection from "../../components/About/Languages/LanguagesSection";
import { toREM } from "../../utils/helpers/toREM";
import {isMobile, isTablet} from "react-device-detect";

const MyProfile = () => {
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [info, setInfo] = useState({
    email: "",
    full_name: "",
    github: { title: "", link: "" },
    linkedin: { title: "", link: "" },
    summary: "",
    title: "",
    languages: [],
  });

  const { data } = useQuery(GET_PROFILE);

  useEffect(() => {
    if (data) {
      const { profile } = data;

      const {
        education: edu,
        experience,
        info: inf,
        publications,
      } = profile.data.attributes;

      setInfo(inf);
      setEducation(edu);
      setExperience(experience);
      setAchievements(publications);
    }
  }, [data]);

  // Define styles for mobile, tablet, and desktop
  const ContainerStyle = isMobile
      ? { mt: {base: 50, sm: 30} }
      : isTablet
          ? { mt: 10 }
          : { mt: 0 };

  return (
    <Box h={"100vh"}>
      <Container
        p={{ base: 5, sm: 12 }}
        color={"black"}
        h={"fit-content"}
        w={"100%"}
        {...ContainerStyle}
      >
        <Heading
          justifyContent={"left"}
          size={"2xl"}
          fontWeight={"500"}
          color={"blue.900"}
          fontFamily={"Montserrat, sans-serif"}
        >
          About
        </Heading>

        <Tabs
          fontFamily={"Montserrat, sans-serif"}
          mt={8}
          align={"start"}
          defaultIndex={0}
        >
          <TabList color={"black"} fontSize={toREM(26)} overflowX="auto">
            <Tab _selected={{ color: "blue.900", fontWeight: "bold" }}>
              Professional Experience
            </Tab>
            <Tab _selected={{ color: "blue.900", fontWeight: "bold" }}>
              Education
            </Tab>
            <Tab _selected={{ color: "blue.900", fontWeight: "bold" }}>
              Achievements
            </Tab>
            <Tab _selected={{ color: "blue.900", fontWeight: "bold" }}>
              Languages
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box position="relative" mt={10}>
                {experience && <ExperienceSection experiences={experience} />}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box position="relative" mt={10}>
                {education && <EducationSection education={education} />}
              </Box>
            </TabPanel>
            <TabPanel>
              {" "}
              <Box position="relative" mt={10}>
                {achievements && (
                  <AchievementsSection achievements={achievements} />
                )}{" "}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box position="relative" mt={10}>
                {info.languages && (
                  <LanguagesSection languages={info.languages} />
                )}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </Box>
  );
};

export default MyProfile;
